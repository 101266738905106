<template>
  <div class="p-4 entry-form-container">
    <form v-if="inSubmit.status" class="submit-hours-form">
      <div class="d-flex flex-row justify-content-between align-items-center">
        <p class="pb-0 mb-0 text-center font-weight-bold mr-5">Eintrag für {{ inSubmit.date }}</p>
        <mdb-tooltip class="ml-3 close-tooltip" trigger="hover" :options="{ placement: 'top' }">
          <span slot="tip">Auswahl abbrechen</span>

          <a @click="closeForm()" slot="reference">
            <mdb-icon class="close-icon" icon="times" size="lg" />
          </a>
        </mdb-tooltip>
      </div>
      <div v-if="submitHours && submitType === 'hours'" class="d-flex justify-content-center">
        <div class="d-flex flex-column">
          <select
            class="browser-default custom-select mt-3"
            name="selectedConstructionProject"
            v-model="selectedConstructionProject"
          >
            <option value="1" selected disabled>Baustelle auswählen</option>
            <option v-for="(item, key) in activeProjects" :key="key">
              {{ item.name }}<span v-if="item.feat">, ({{ item.feat }})</span>
            </option>
          </select>
          <input
            class="form-control form-control text-center mx-auto mt-2"
            type="text"
            name="workedHours"
            placeholder="Stunden eintragen"
            v-model="workedHours"
          />
          <button
            class="btn btn-warning mt-3"
            type="button"
            @click="addEntry(weekNumber, inSubmit.day)"
          >
            Stunden Speichern
          </button>
          <div class="d-flex flex-row justify-content-between">
            <button
              class="btn btn-danger"
              type="button"
              @click="addillnessEntry(weekNumber, inSubmit.day)"
            >
              Am {{ inSubmit.day }} krank
            </button>
            <button
              v-if="submitHours"
              class="btn btn-success"
              @click="changeSubmitType('vacation')"
              type="button"
            >
              Am {{ inSubmit.day }} Urlaub
            </button>
            <button
              v-if="submitHours"
              class="btn btn-info"
              @click="addPublicHolidayEntry(weekNumber, inSubmit.day)"
              type="button"
            >
              Am {{ inSubmit.day }} Feiertag
            </button>
          </div>
        </div>
      </div>
      <div v-if="submitHours && submitType === 'vacation'" class="mt-5 d-flex flex-column">
        <select
          class="browser-default custom-select"
          name="selectedVacationTyp"
          v-model="selectedVacationTyp"
        >
          <option selected>Urlaub</option>
          <option>unbezahlter Urlaub</option>
          <option>Zeitkonto</option>
        </select>
        <input
          v-if="selectedVacationTyp === 'Zeitkonto'"
          class="form-control form-control text-center mx-auto"
          type="text"
          name="vacationHours"
          placeholder="Zeitkontostunden eintragen"
          v-model="vacationHours"
        />
        <button
          class="btn btn-warning mt-3"
          type="button"
          @click="addVacationEntry(weekNumber, inSubmit.day)"
        >
          Urlaub speichern
        </button>
        <button
          v-if="submitType === 'vacation'"
          class="btn btn-success mt-3"
          @click="changeSubmitType('hours')"
          type="button"
        >
          Stunden eintragen
        </button>
      </div>
    </form>
    <form v-if="inSelection" class="submit-edit-hours-form mx-auto">
      <div class="d-flex flex-column">
        <div class="mb-3 d-flex flex-row align-items-center justify-content-between">
          <p class="mb-0 text-center font-weight-bold">Markierte Eintrag bearbeiten oder löschen</p>
          <mdb-tooltip class="ml-3 close-tooltip" trigger="hover" :options="{ placement: 'top' }">
            <span slot="tip">Auswahl abbrechen</span>

            <a @click="unselectEntry()" slot="reference">
              <mdb-icon class="close-icon" icon="times" size="lg" />
            </a>
          </mdb-tooltip>
        </div>
        <div class="mx-auto">
          <select
            v-if="selectedEntry.projects !== 'Urlaub' && selectedEntry.projects !== 'Zeitkonto'"
            class="browser-default custom-select"
            name="editConstructionProject"
            v-model="editConstructionProject"
          >
            <option selected value="1">Baustelle ändern</option>
            <option v-for="(item, key) in projects" :key="key">
              {{ item.name }}<span v-if="item.feat">, ({{ item.feat }})</span>
            </option>
          </select>
          <select
            v-if="selectedEntry.projects === 'Urlaub' || selectedEntry.projects === 'Zeitkonto'"
            class="browser-default custom-select"
            name="editConstructionProject"
            v-model="editConstructionProject"
          >
            <option selected>Urlaub</option>
            <option>Zeitkonto</option>
          </select>
          <input
            v-if="
              (selectedEntry.projects !== 'Urlaub' && editConstructionProject !== 'Urlaub') ||
              editConstructionProject === 'Zeitkonto'
            "
            class="form-control form-control text-center mx-auto my-2"
            type="text"
            name="editWorkedHours"
            placeholder="Stunden eintragen"
            v-model="editWorkedHours"
          />
          <div class="d-flex flex-row">
            <button
              class="btn btn-warning w-50"
              type="button"
              @click="updateEntry(selectedEntry.id)"
            >
              Eintrag Bearbeiten
            </button>
            <button
              class="btn btn-danger w-50"
              type="button"
              @click="deleteEntry(selectedEntry.id)"
            >
              Eintrag<br />Löschen
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mdbIcon, mdbTooltip } from 'mdbvue';
import { mapGetters } from 'vuex';
export default {
  props: [
    'weekNumber',
    'inSubmit',
    'submitHours',
    'submitVacation',
    'inSelection',
    'selectedEntry',
    'selectedDay',
    'selectedProject',
    'selectedHours',
    'year',
  ],
  components: {
    mdbIcon,
    mdbTooltip,
  },
  data() {
    return {
      selectedConstructionProject: 1,
      workedHours: null,
      selectedVacationTyp: 'Urlaub',
      submitType: 'hours',
      editedWorkedHours: '',
      editedConstructionProject: '',
      vacationHours: '',
      currentYear: null,
    };
  },
  computed: {
    ...mapGetters(['projects', 'activeProjects']),
    editWorkedHours: {
      get() {
        return this.selectedHours;
      },
      set(editWorkedHours) {
        this.editedWorkedHours = editWorkedHours;
      },
    },
    editConstructionProject: {
      get() {
        return this.selectedProject;
      },
      set(editConstructionProject) {
        this.editedConstructionProject = editConstructionProject;
      },
    },
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
  },
  methods: {
    changeSubmitType(type) {
      this.submitType = type;
    },
    /**
     * ! ENTRY HANDLING
     * add entry, illness, vacation
     * update
     * delete
     */
    addEntry(cw, day) {
      const data = {
        week: cw,
        day: day,
        project: this.selectedConstructionProject,
        hours: this.workedHours,
        productive: this.selectedConstructionProject.includes('(') ? 0 : 1,
        unproductive: this.selectedConstructionProject.includes('(') ? 1 : 0,
        year: this.year,
      };
      this.$store.dispatch('ADD_DAY_ENTRY', data).then(() => {
        this.$emit('formSubmited', this.inSubmit.day);
        this.selectedConstructionProject = 1;
        this.workedHours = null;
      });
    },
    addVacationEntry(cw, day) {
      let vacationHours = 0;
      if (this.selectedVacationTyp === 'Zeitkonto' && this.vacationHours !== null) {
        vacationHours = parseInt(this.vacationHours);
      } else if (
        this.selectedVacationTyp === 'Urlaub' ||
        this.selectedVacationTyp === 'unbezahlter Urlaub'
      ) {
        vacationHours = 0;
      }
      const data = {
        week: cw,
        day: day,
        project: this.selectedVacationTyp,
        hours: vacationHours,
        productive: 0,
        unproductive: 1,
        year: this.year,
      };
      this.$store.dispatch('ADD_DAY_ENTRY', data).then(() => {
        this.$emit('formSubmited', this.inSubmit.day);
      });
    },
    addillnessEntry(cw, day) {
      const data = {
        week: cw,
        day: day,
        project: 'Krank',
        hours: 0,
        productive: 0,
        unproductive: 1,
        year: this.year,
      };
      this.$store.dispatch('ADD_DAY_ENTRY', data).then(() => {
        this.$emit('formSubmited', this.inSubmit.day);
      });
    },
    addPublicHolidayEntry(cw, day) {
      const data = {
        week: cw,
        day: day,
        project: 'Feiertag',
        hours: 0,
        productive: 0,
        unproductive: 1,
        year: this.year,
      };
      this.$store.dispatch('ADD_DAY_ENTRY', data).then(() => {
        this.$emit('formSubmited', this.inSubmit.day);
      });
    },
    updateEntry(id) {
      let hours;
      if (this.editedWorkedHours === '') {
        hours = this.selectedHours;
      } else {
        hours = this.editedWorkedHours;
      }
      let project;
      if (this.editedConstructionProject === '') {
        project = this.selectedProject;
      } else {
        project = this.editedConstructionProject;
      }
      const data = {
        id: id,
        hours: this.editedConstructionProject === 'Urlaub' ? 0 : hours,
        project: project,
        productive: this.editedConstructionProject.includes(',') ? 0 : 1,
        unproductive: this.editedConstructionProject.includes(',') ? 1 : 0,
        year: this.year,
      };
      this.$store.dispatch('UPDATE_DAY_ENTRY', data).then(() => {
        this.$emit('unselectEntry');
        this.$emit('reloadWeekData', this.selectedDay);
      });
    },
    deleteEntry(id) {
      this.$store.dispatch('DELETE_DAY_ENTRY', id).then(() => {
        this.$emit('unselectEntry');
        this.$emit('reloadWeekData', this.selectedDay);
      });
    },
    /**
     * ! FORM HANDLING
     */
    closeForm() {
      this.$emit('closeForm');
    },
    unselectEntry() {
      this.$emit('unselectEntry');
    },
    changeSubmitForm(form) {
      if (form === 'vacation') {
        this.submitVacation = true;
        this.submitHours = false;
      } else if (form === 'hours') {
        this.submitHours = true;
        this.submitVacation = false;
      }
    },
  },
};
</script>
