<template>
  <div class="d-flex flex-column working-period w-100">
    <div class="d-flex">
      <div class="d-flex flex-column align-items-center w-50">
        <small>Startzeit</small>
        <input
          class="p-1 working-period__input working-period__input--start"
          type="time"
          v-model="start"
        />
      </div>
      <div class="d-flex flex-column align-items-center w-50">
        <small>Endzeit</small>
        <input
          class="p-1 working-period__input working-period__input--end"
          type="time"
          v-model="end"
        />
      </div>
    </div>
    <button
      v-if="status === 'empty'"
      :class="{
        disabled: start === '' || end === '',
      }"
      class="btn btn-success p-2"
      type="button"
      @click="saveWorkingPeriod(day, week)"
    >
      Zeit Speichern
    </button>
    <div v-if="status === 'edit'" class="d-flex flex-row">
      <!-- todo: true need to be dynamic -->
      <button
        class="btn btn-success p-2 w-75"
        type="button"
        @click="updateWorkingTime(id, day, week)"
      >
        Zeit Bearbeiten
      </button>
      <button class="btn btn-danger p-2 w-25" @click="deleteWorkingTime(id, day)">
        <mdb-tooltip
          class="stundentool-tooltip--black"
          trigger="hover"
          :options="{ placement: 'top' }"
        >
          <span slot="tip">Start- und Endzeit löschen</span>
          <a slot="reference"><mdb-icon icon="times" class="white-text" size="lg" /></a>
        </mdb-tooltip>
      </button>
    </div>
  </div>
</template>

<script>
import { mdbIcon, mdbTooltip } from 'mdbvue';
export default {
  components: {
    mdbIcon,
    mdbTooltip,
  },
  props: ['start', 'end', 'id', 'day', 'week', 'status', 'year'],
  data() {
    return {
      // start: '',
      // end: '',
      currentYear: null,
    };
  },
  computed: {
    // startTime: {
    //   get() {
    //     return this.start;
    //   },
    //   set(start) {
    //     this.start = start;
    //   },
    // },
    // endTime: {
    //   get() {
    //     return this.end;
    //   },
    //   set(end) {
    //     this.end = end;
    //   },
    // },
  },
  mounted() {
    // this.start = this.start;
    // this.end = this.end;
    this.currentYear = new Date().getFullYear();
  },
  methods: {
    saveWorkingPeriod(day, cw) {
      let start;
      let end;
      if (this.start !== null) {
        start = this.start;
      } else {
        start = this.start;
      }
      if (this.end !== null) {
        end = this.end;
      } else {
        end = this.end;
      }

      // ! ANGUCKEN
      // if (start.substr(3) === '00' || start.substr(3) === '30') {
      //   startCorrect = true;
      // } else {
      //   startCorrect = false;
      // }
      // if (end.substr(3) === '00' || end.substr(3) === '30') {
      //   endCorrect = true;
      // } else {
      //   endCorrect = false;
      // }

      if (start !== '' && end !== '') {
        this.$store
          .dispatch('ADD_WORKING_TIME', this.prepareData(start, end, cw, null, day))
          .then(() => {
            this.$emit('reloadWorkingPeriod');
          });
      } else {
        this.activateNotification(
          'Fehler beim Speichern von der Start- und Endzeit für ' + day + '!',
          'error',
        );
      }
    },
    updateWorkingTime(id, day) {
      let start;
      let end;
      if (this.start !== null) {
        start = this.start;
      } else {
        start = this.start;
      }
      if (this.end !== null) {
        end = this.end;
      } else {
        end = this.end;
      }

      if (start !== '' && end !== '') {
        this.$store
          .dispatch('UPDATE_WORKING_TIME', this.prepareData(start, end, null, id, day))
          .then(() => {
            this.$emit('reloadWorkingPeriod');
          });
      } else {
        this.activateNotification(
          'Fehler beim Speichern von der Start- und Endzeit für ' + day + '!',
          'error',
        );
      }
    },
    deleteWorkingTime(id, day) {
      this.$store.dispatch('DELETE_WORKING_TIME', id).then(() => {
        this.$emit('reloadWorkingPeriod');
        this.$emit('deleteWorkingPeriodFromDay', day);
        this.start = '';
        this.end = '';
      });
    },
    /**
     * ! Helper Functions
     */
    differenceTimeStamp(start, end) {
      start = start.split(':');
      end = end.split(':');
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);

      if (hours < 0) {
        hours = hours + 24;
      }
      return (hours < 9 ? '0' : '') + hours + ':' + (minutes < 9 ? '0' : '') + minutes;
    },
    prepareData(start, end, cw, id, day) {
      let calculatedHours = this.differenceTimeStamp(start, end);

      let diff = calculatedHours.split(':');

      let workingBreak;

      let calculationSaver = calculatedHours.split(':');
      if (parseInt(diff[0]) > 6 || (parseInt(diff[0]) >= 6 && parseInt(diff[1]) > 0)) {
        workingBreak = 1;

        if (calculationSaver[1] === '00') {
          calculationSaver[0] = parseInt(calculationSaver[0]) - 1;
          calculationSaver[1] = ',5';
          calculatedHours = calculationSaver[0] + calculationSaver[1];
        } else if (calculationSaver[1] === '30') {
          calculationSaver[1] = '';
          calculatedHours = parseInt(calculationSaver[0]) + calculationSaver[1];
        }
      } else {
        workingBreak = 0;
        if (calculationSaver[1] === '00') {
          calculationSaver[0] = parseInt(calculationSaver[0]);
          calculationSaver[1] = '';
          calculatedHours = calculationSaver[0] + calculationSaver[1];
        } else if (calculationSaver[1] === '30') {
          calculationSaver[1] = ',5';
          calculatedHours = parseInt(calculationSaver[0]) + calculationSaver[1];
        }
      }
      const data = {
        id: id,
        week: cw,
        day: day,
        startTime: start,
        endTime: end,
        break: workingBreak,
        total: calculatedHours,
        year: this.year,
      };
      return data;
    },
  },
};
</script>
