<template>
  <div class="stundentool">
    <div class="w-100">
      <div class="row m-0">
        <div class="card mt-5 mx-5 p-5 w-100">
          <h1 class="text-center">
            <span v-if="currentWeekSaver === weekNumber">Wir befinden uns in </span>
            <span class="font-weight-bold">KW{{ weekNumber }} {{ currentYear }}</span>
          </h1>
          <div v-if="calenderLoading" class="spinner-border mx-auto" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div
            v-if="!calenderLoading"
            class="hours-calender d-flex flex-wrap justify-content-center"
          >
            <i
              class="fas fa-arrow-circle-right fa-2x nextWeek"
              @click="closeForms(), resetWeekTo('next')"
            ></i>
            <i
              class="fas fa-arrow-circle-left fa-2x lastWeek"
              @click="closeForms(), resetWeekTo('last')"
            ></i>
            <div
              class="hours-calender__day d-flex flex-column text-center mx-2"
              v-for="(element, key) in weekData"
              :key="key"
            >
              <div
                :class="{
                  'font-weight-bold': element.current,
                  'text-info': element.current,
                  'error-text': element.error !== '',
                }"
              >
                {{ element.date }}
              </div>
              <div
                class="hours-calender__day-area mx-auto d-flex flex-column"
                :class="{
                  'hours-calender__day-area--filled': element.data.length > 0,
                  'justify-content-between': element.data.length > 0,
                  'align-items-center': element.data.length === 0,
                  'justify-content-center': element.data.length === 0,
                }"
              >
                <div v-if="element.data.length > 0">
                  <mdb-badge
                    class="data-badge d-flex flex-column"
                    v-for="(dayData, key) in element.data"
                    :class="{
                      'border border-dark':
                        selectedEntry.id === dayData.id && element.day === dayData.day,
                    }"
                    :key="key"
                    :color="getBadgeColor(dayData.project)"
                  >
                    <span
                      class="badge-text"
                      @click="
                        selectEntry(dayData.id, element.day, dayData.project, dayData.hours),
                          (modal = true)
                      "
                      >{{ dayData.project }} <br />
                      {{ dayData.hours }} Stunden</span
                    >
                  </mdb-badge>
                </div>
                <i
                  v-if="!element.submitFormOpen"
                  class="far fa-plus-square fa-2x"
                  @click="openSubmitForm(element.day, element.date), (modal = true)"
                ></i>
                <span @click="closeForms()"
                  ><mdb-icon
                    class="close-day-form red-text"
                    size="2x"
                    v-if="element.submitFormOpen"
                    icon="times-circle"
                /></span>
              </div>
              <div class="mt-3 hours-calender__day-time d-flex flex-column">
                <div class="d-flex">
                  <working-period
                    :start="element.startTime"
                    :end="element.endTime"
                    :id="element.timeId"
                    :day="element.day"
                    :week="weekNumber"
                    :status="element.startTime && element.endTime ? 'edit' : 'empty'"
                    :year="currentYear"
                    v-on:reloadWorkingPeriod="setWorkingTime(weekNumber)"
                    v-on:deleteWorkingPeriodFromDay="deleteWorkingTimeFromDay($event)"
                  ></working-period>
                </div>
                <div class="mt-3 error-text" v-if="element.error !== ''">{{ element.error }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mdb-modal
      v-on:hide="closeForms()"
      v-on:close="closeForms()"
      centered
      :show="modal"
      @close="modal = false"
    >
      <mdb-modal-body>
        <entry-form
          :weekNumber="weekNumber"
          :inSubmit="inSubmit"
          :submitHours="submitHours"
          :submitVacation="submitVacation"
          :inSelection="inSelection"
          :selectedEntry="selectedEntry"
          :selectedProject="editConstructionProject"
          :selectedHours="editWorkedHours"
          :selectedDay="selectedEntry.day"
          :year="currentYear"
          v-on:closeForm="closeForms()"
          v-on:unselectEntry="unselectEntry()"
          v-on:formSubmited="setWeekData(), closeSubmitForm($event)"
          v-on:reloadWeekData="setWeekData()"
        ></entry-form>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import { mdbBadge, mdbIcon, mdbModal, mdbModalBody } from 'mdbvue';
import { mapGetters } from 'vuex';
import WorkingPeriod from '../components/stundentool/WorkingPeriod';
import EntryForm from '../components/stundentool/EntryForm';
import axios from 'axios';

export default {
  components: {
    mdbBadge,
    mdbIcon,
    'working-period': WorkingPeriod,
    'entry-form': EntryForm,
    mdbModal,
    mdbModalBody,
  },
  data() {
    return {
      // ! more used variables
      weekNumber: '',

      // ! set current day
      currentDate: null,

      // ! Init array of day objects
      weekData: [],

      // ! Submit hours
      workedHours: null,

      // ! Submit edit hours
      editWorkedHours: null,
      editConstructionProject: 1,

      // ! Submit
      inSubmit: {
        date: null,
        status: false,
        day: null,
      },

      // ! Selection
      inSelection: false,
      selectedEntry: {},

      // ! Loading data
      calenderLoading: false,

      // ! Saver
      currentWeekSaver: null,
      currentDaySaver: '',

      // ! submitForm
      submitHours: true,
      submitVacation: false,

      weekDataEmpty: false,

      currentYear: null,

      modal: false,
    };
  },

  mounted() {
    this.currentYear = new Date().getFullYear();
    console.log(this.currentYear);

    const weekDay = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ];

    for (let i = 1; i < weekDay.length; i++) {
      const obj = {
        day: weekDay[i],
        date: '',
        current: false,
        submitFormOpen: false,
        data: [],
        timeId: null,
        startTime: '',
        endTime: '',
        total: '0',
        error: '',
      };
      this.weekData.push(obj);
    }
    if (localStorage.getItem('JWT') !== null && localStorage.getItem('JWT') !== '') {
      this.$store.dispatch('REFRESH_TOKEN', localStorage.getItem('JWT')).then(() => {
        var current = new Date(),
          dayNumber = current.getDay();

        const currentDay = weekDay[dayNumber];
        if (currentDay !== 'Sonntag') {
          this.weekData.find((day) => day.day === currentDay).current = true;
        }
        const result = this.getWeekNumber(new Date());
        this.weekNumber = result[1];

        // ! A saver for day/week
        this.currentWeekSaver = result[1];
        this.currentDaySaver = currentDay;

        /**
         * Initial loading
         */
        this.changeWeekDates();
        this.setWeekData();
        this.setWorkingTime(this.weekNumber);
        this.$store.dispatch('GET_PROJECTS');
      });
    }
  },
  computed: {
    ...mapGetters(['displayedData', 'accessToken', 'projects']),
  },
  methods: {
    /**
     * ! set week data & working time
     */
    setWeekData() {
      this.calenderLoading = true;
      this.$store
        .dispatch('GET_WEEK_DATA', { week: this.weekNumber, year: this.currentYear })
        .then(() => {
          this.weekData.forEach((object) => {
            object.data = [];
          });
          this.displayedData.forEach((object) => {
            this.weekData.find((day) => day.day === object.day).data.push(object);
          });
          this.calenderLoading = false;
          if (this.weekDataNotEmpty) {
            this.checkForHoursDifferences();
          }
          this.weekDataNotEmpty = true;
        });
    },
    setWorkingTime(cw) {
      axios({
        url: this.$store.state.apiDomain + '/working-time/get-week',
        data: {
          week: cw,
          year: this.currentYear,
        },
        headers: { Authorization: `Bearer ${this.accessToken}` },
        method: 'POST',
      }).then((response) => {
        this.weekData.forEach((day) => {
          (day.timeId = null), (day.startTime = ''), (day.endTime = '');
        });
        const responseData = response.data;
        responseData.forEach((entry) => {
          const entryDay = this.weekData.find((day) => day.day === entry.day);
          entryDay.timeId = entry.id;
          entryDay.startTime = entry.start_time;
          entryDay.endTime = entry.end_time;
          entryDay.total = entry.total;
          entryDay.error = '';
        });
        this.checkForHoursDifferences();
      });
    },

    checkForHoursDifferences() {
      this.weekData.forEach((dayData) => {
        dayData.error = '';
        let dayWorkedHoursInProjects = 0;
        dayData.data.forEach((entry) => {
          let entryHours = 0;
          entry.hours.includes(',')
            ? (entryHours = entry.hours.replace(',', '.'))
            : (entryHours = entry.hours);
          dayWorkedHoursInProjects = dayWorkedHoursInProjects + parseFloat(entryHours);
        });

        let dayDataTotal = 0;
        dayData.total.includes(',')
          ? (dayDataTotal = dayData.total.replace(',', '.'))
          : (dayDataTotal = dayData.total);

        if (
          dayData.total !== '0' &&
          dayWorkedHoursInProjects > 0 &&
          dayWorkedHoursInProjects !== parseFloat(dayDataTotal)
        ) {
          if (parseFloat(dayData.total) > 6) {
            dayData.error =
              'Arbeitszeit stimmt nicht mit Baustellenstunden überein! (Pause abgezogen)';
          } else {
            dayData.error = 'Arbeitszeit stimmt nicht mit Baustellenstunden überein!';
          }
        }
      });
    },

    deleteWorkingTimeFromDay(day) {
      const elementDay = this.weekData.find((dayElement) => dayElement.day === day);
      elementDay.total = '0';
      elementDay.startTime = '';
      elementDay.endTime = '';
    },

    /**
     * ! FORM HANDLING
     */
    openSubmitForm(day, date) {
      this.weekData.find((item) => item.day === day).submitFormOpen = true;
      this.inSubmit.date = date;
      this.inSubmit.day = day;
      this.inSubmit.status = true;
    },
    closeSubmitForm(day) {
      this.weekData.find((item) => item.day === day).submitFormOpen = false;
      this.inSubmit.date = null;
      this.inSubmit.day = null;
      this.inSubmit.status = false;
      this.modal = false;
    },
    closeForms() {
      this.weekData.forEach((element) => {
        element.submitFormOpen = false;
      });

      this.inSubmit = {
        date: null,
        status: false,
        day: null,
      };
      this.selectedEntry = {};
      this.inSelection = false;

      this.submitHours = true;
      this.submitVacation = false;

      this.modal = false;
    },

    /**
     * ! SELECT HANDLING
     */
    selectEntry(id, day, projects, hours) {
      this.selectedEntry = {
        id: id,
        day: day,
        projects: projects,
        hours: hours,
      };
      this.editWorkedHours = hours;
      this.editConstructionProject = projects;
      this.inSelection = true;
    },
    unselectEntry() {
      this.selectedEntry = {};
      this.inSelection = false;
      this.modal = false;
    },

    /**
     * ! WEEK SWITCH HANDLING
     */
    resetWeekTo(selection) {
      if (selection === 'last') {
        this.weekNumber = parseInt(this.weekNumber) - 1;
        this.weekData.forEach((object) => {
          object.startTime = '';
          object.endTime = '';
          object.timeId = null;
          object.current = false;
        });
        if (this.weekNumber === this.currentWeekSaver) {
          if (this.currentDaySaver !== 'Sonntag') {
            this.weekData.find((day) => day.day === this.currentDaySaver).current = true;
          }
        }
        if (this.weekNumber === 0) {
          this.weekNumber = 52;
          this.currentYear = this.currentYear - 1;
        }
        this.setWeekData();
        this.setWorkingTime(this.weekNumber);
        this.changeWeekDates();
      } else if (selection === 'next') {
        if (this.weekNumber === 52 || this.weekNumber === 53) {
          // need to check for weeks in year (only 52 weeks possible)
          const lastDayInDecember = new Date(this.currentYear, 11, 31);
          if (this.getWeekNumber(lastDayInDecember)[1] === 53) {
            this.weekNumber = 53;
          } else {
            this.weekNumber = 1;
            this.currentYear = this.currentYear + 1;
          }
        } else {
          this.weekNumber = parseInt(this.weekNumber) + 1;
        }
        this.weekData.forEach((object) => {
          object.startTime = '';
          object.endTime = '';
          object.timeId = null;
          object.current = false;
        });
        if (this.weekNumber === this.currentWeekSaver) {
          if (this.currentDaySaver !== 'Sonntag') {
            this.weekData.find((day) => day.day === this.currentDaySaver).current = true;
          }
        }
        this.setWeekData();
        this.setWorkingTime(this.weekNumber);
        this.changeWeekDates();
      }
    },

    /**
     *
     * @return Badge color for different project types
     */
    getBadgeColor(project) {
      if (
        project === 'Krank' ||
        project === 'Urlaub' ||
        project === 'unbezahlter Urlaub' ||
        project === 'Zeitkonto'
      ) {
        switch (project) {
          case 'Krank':
            return 'secondary';
          case 'Urlaub':
            return 'unique-color';
          case 'unbezahlter Urlaub':
            return 'unique-color';
          case 'Zeitkonto':
            return 'stylish-color';
        }
      } else {
        return 'info';
      }
    },

    /**
     * ! HELPER FUNCTIONS
     */
    changeWeekDates() {
      const deWeek = ['Mo:', 'Di:', 'Mi:', 'Do:', 'Fr:', 'Sa:', 'So:'];
      const weekArray = this.getWeek(this.getDateOfISOWeek(this.weekNumber, this.currentYear));
      // Comment because of year bug
      // const yearOfDate = weekArray[0].getFullYear();
      // this.currentYear = yearOfDate;
      for (let i = 1; i <= 6; i++) {
        this.weekData[i - 1].date =
          deWeek[i - 1] + ' ' + new Date(weekArray[i]).toLocaleDateString('de-DE');
      }
    },
    getDateOfISOWeek(w, y) {
      const simple = new Date(y, 0, 1 + (w - 1) * 7);
      const dow = simple.getDay();
      const ISOweekStart = simple;
      if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
      return ISOweekStart;
    },
    getWeek(fromDate) {
      const sunday = new Date(fromDate.setDate(fromDate.getDate() - fromDate.getDay())),
        result = [new Date(sunday)];
      while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 0) {
        result.push(new Date(sunday));
      }
      return result;
    },
    getWeekNumber(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
      var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
      var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
      return [d.getUTCFullYear(), weekNo];
    },
    differenceTimeStamp(start, end) {
      start = start.split(':');
      end = end.split(':');
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);

      if (hours < 0) {
        hours = hours + 24;
      }
      return (hours < 9 ? '0' : '') + hours + ':' + (minutes < 9 ? '0' : '') + minutes;
    },
  },
};
</script>
